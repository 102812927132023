import { Component, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { ModalController } from '@ionic/angular';
import { SignatureService } from './services/signature.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit {

  constructor(
    private _modalController: ModalController,
    private _signatureService: SignatureService
  ) { }

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'dotSize': 0.1,
    'minWidth': 0,
    'canvasWidth': 406,
    'canvasHeight': 100,
    'canvasBorder': '1px solid #000'
  };

  ngOnInit() { 
 
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    this._signatureService.signature$.subscribe(data => {
      if(data) {
        this.signaturePad.fromDataURL(data);
      }
    })
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  useSign() {
    this._signatureService.setSignature(this.signaturePad.toDataURL())
    // this._signatureService.signed = true
    // console.log(this.signaturePad.toDataURL())
    this._modalController.dismiss();
  }

  closeModal() {
    this._modalController.dismiss();
  }

  clearSign() {
    this._signatureService.clearSignature();
    this.signaturePad.clear();
  }

}
