import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FoldersComponent } from './folder/folders/folders.component'
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { GuidelinesModalComponent } from './guidelines-modal/guidelines-modal.component';
import { RouterModule } from '@angular/router';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor'
import { TextEditorComponent } from './text-editor/text-editor.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { AccordionComponent } from './accordion/accordion.component';
import { NewFolderModalComponent } from './folder/new-folder-modal/new-folder-modal.component';
import { PanelComponent } from './panel/panel.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { ContentDetailComponent } from './content-detail/content-detail.component';
import { UploadService } from './upload/services/upload-item-modal.service';
import { UploadFormDocComponent } from './upload/upload.component';
import { RiskMatrixComponent } from './risk-matrix/risk-matrix.component';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { CalendarComponent } from './calendar/calendar.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AdminComponent } from './admin/admin.component';
import { NameSignpadComponent } from './name-signpad/name-signpad.component';
import { SignatureBoxComponent } from './signature-box/signature-box.component';
import { IncidentChartComponent } from './incident-chart/incident-chart.component';
import { ChartsModule } from 'ng2-charts';
import { FaultyChartComponent } from './faulty-chart/faulty-chart.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        RichTextEditorAllModule,
        Ionic4DatepickerModule,
        SignaturePadModule,
        ChartsModule
    ],
    providers: [
        UploadService
    ],
    declarations: [
        ForgotPasswordComponent,
        LogoutComponent,
        GuidelinesModalComponent,
        BreadcrumbComponent,
        FoldersComponent,
        TextEditorComponent,
        PageHeaderComponent,
        AccordionComponent,
        NewFolderModalComponent,
        PanelComponent,
        FormModalComponent,
        UploadDocumentsComponent,
        ContentDetailComponent,
        UploadFormDocComponent,
        RiskMatrixComponent,
        CalendarComponent,
        SignaturePadComponent,
        ToggleButtonComponent,
        ConfirmModalComponent,
        AdminComponent,
        NameSignpadComponent,
        SignatureBoxComponent,
        IncidentChartComponent,
        FaultyChartComponent
    ],
    exports: [BreadcrumbComponent,
        GuidelinesModalComponent,
        FoldersComponent,
        TextEditorComponent,
        PageHeaderComponent,
        AccordionComponent,
        NewFolderModalComponent,
        PanelComponent,
        FormModalComponent,
        UploadDocumentsComponent,
        ContentDetailComponent,
        UploadFormDocComponent,
        RiskMatrixComponent,
        CalendarComponent,
        SignaturePadComponent,
        ToggleButtonComponent,
        ConfirmModalComponent,
        NameSignpadComponent,
        SignatureBoxComponent,
        IncidentChartComponent,
        FaultyChartComponent
    ],
    entryComponents: [
        ForgotPasswordComponent,
        LogoutComponent,
        NewFolderModalComponent,
        UploadFormDocComponent,
        SignaturePadComponent,
        ConfirmModalComponent,
        AdminComponent
    ]
})
export class ComponentsModule { }