import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-guidelines-modal',
  templateUrl: './guidelines-modal.component.html',
  styleUrls: ['./guidelines-modal.component.scss'],
})
export class GuidelinesModalComponent implements OnInit {



  constructor(private _modalController:ModalController) { }

  ngOnInit() {}

  closeModal(){
    this._modalController.dismiss();
  }

}
