import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IContentDetail } from './interface/content-detail-interface';
import { faEdit, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.scss'],
})
export class ContentDetailComponent implements OnInit {

  @Input() recordSet: IContentDetail[] = [];
  @Input() noRecordsFoundText: string = '';
  @Input() modalTitle: string;
  @Output() removeItem = new EventEmitter<IContentDetail>();
  @Output() editItem = new EventEmitter<IContentDetail>();
  @Output() viewItem = new EventEmitter<IContentDetail>();

  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faEye = faEye;

  constructor(private _modalController: ModalController) { }

  ngOnInit() {
    (this.recordSet)
  }

  async removeSelectedItem(itemToDelete: IContentDetail) {
    const modal = await this._modalController.create({
      component: ConfirmModalComponent,
      cssClass: 'deleteModal',
      backdropDismiss: true,
      componentProps: {
        'title': this.modalTitle,
        'body': itemToDelete.title,
        'action': 'delete'
      },
    });

    modal.onDidDismiss().then((result) => {
      if (result.data.action) {
        this.removeItem.emit(itemToDelete);
      }
    });
    await modal.present();
  }

  editSelectedItem(itemToDelete: IContentDetail) {
    this.editItem.emit(itemToDelete);
  }

  viewSelectedItem(itemToDelete: IContentDetail) {
    this.viewItem.emit(itemToDelete)
  }

}
