import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserlevelService } from './services/userlevel.service';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/menu/menu/menu.module').then(m => m.MenuPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'taskmanager',
    loadChildren: () => import('./pages/taskmanager/taskmanager.module').then(m => m.TaskmanagerPageModule),
  },
  {
    path: 'checklists',
    loadChildren: () => import('./pages/checklists/checklists.module').then(m => m.ChecklistsPageModule)
  },
  {
    path: 'incidentmanager',
    loadChildren: () => import('./pages/incidentmanager/incidentmanager.module').then(m => m.IncidentmanagerPageModule)
  },
  {
    path: 'ohssystem',
    loadChildren: () => import('./pages/ohssystem/ohssystem.module').then(m => m.OhssystemPageModule),
  },
  {
    path: 'companydocs',
    loadChildren: () => import('./pages/companydocs/companydocs.module').then(m => m.CompanydocsPageModule),
  },
  {
    path: 'trainingcalendar',
    loadChildren: () => import('./pages/trainingcalendar/trainingcalendar.module').then(m => m.TrainingcalendarPageModule)
  },
  {
    path: 'legislation',
    loadChildren: () => import('./pages/legislation/legislation.module').then(m => m.LegislationPageModule)
  },
  {
    path: 'servergroup',
    loadChildren: () => import('./pages/admin/servergroup/servergroup.module').then( m => m.ServergroupPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
