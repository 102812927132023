import { Component, OnInit } from '@angular/core';
import { GetIncidentCountService } from 'src/app/services/getincidentcount.service';
import { Storage } from '@ionic/storage';
import { IResposne } from 'src/app/services/graphql.service';
import { ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-incident-chart',
  templateUrl: './incident-chart.component.html',
  styleUrls: ['./incident-chart.component.scss'],
})
export class IncidentChartComponent implements OnInit {

  public access_token: string;
  barIncidentMonthChartData: ChartDataSets[] = [{ data: [], label: 'All Incident By Assignment' }];
  barIncidentYearChartData: ChartDataSets[] = [{ data: [], label: 'All Incident By Assignment' }];
  barChartLabels: Label[] = ['Near Miss', 'Minor', 'Major', 'Fatality', 'Security'];
  barChartType = 'bar'
  public barChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            max: 100,
            beginAtZero: true
          }
        }
      ],
    },
    pan: {
      enabled: true,
      mode: 'xy'
    },
    zoom: {
      enabled: true,
      mode: 'xy'
    },
  };

  public barChartColors: Color[] = [
    { backgroundColor: ['#6FC047', '#F3F71F', '#EEA51E', '#CD3F19', '#4747C0'] },
  ];

  // public barChartColors: Color[] = [
  //   { backgroundColor: '#ffe0e6' },
  //   { backgroundColor: '#d6ecfa' },
  // ]

  public barChartLegend = true;
  incidentCounts: any = {};

  constructor(private _getIncidentCountService: GetIncidentCountService,
    private _storage: Storage) { }

  ngOnInit() {
    this._storage.get('access_token').then((value) => {
      this.access_token = value
      this.getIncidentCounts()
    });
  }

  getIncidentCounts() {
    this.barIncidentMonthChartData[0].data = []
    this.barIncidentYearChartData[0].data = []
    this._getIncidentCountService.getIncidentCount(this.access_token)
      .subscribe((res: IResposne) => {
        this.incidentCounts = { ...res.data.incidentCounts };

        this.barIncidentMonthChartData = [
          {
            data: [this.incidentCounts.nearMissIncidentYearCount,
            this.incidentCounts.minorIncidentMonthCount,
            this.incidentCounts.majorIncidentMonthCount, 
            this.incidentCounts.fatalityIncidentMonthCount,
            this.incidentCounts.securityIncidentMonthCount
            ], label: 'Month'
          }
        ];

        this.barIncidentYearChartData = [
          {
            data: [this.incidentCounts.nearMissIncidentYearCount,
            this.incidentCounts.minorIncidentYearCount,
            this.incidentCounts.majorIncidentYearCount,
            this.incidentCounts.fatalityIncidentYearCount,
            this.incidentCounts.securityIncidentYearCount
            ], label: 'Annual'
          }
        ];
      });
  }

}
