import { Injectable } from '@angular/core';
import { GraphqlService, IResposne } from './graphql.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetIncidentCountService {

  constructor(private _graphqlService: GraphqlService,
    private _http: HttpClient) { }

  getIncidentCount(access_token) {
    let folderStruct = {
      function: "incidentCounts",
      data: {},
      returnData: {
        keys: "nearMissIncidentMonthCount,nearMissIncidentYearCount,minorIncidentMonthCount,minorIncidentYearCount,majorIncidentMonthCount,majorIncidentYearCount,fatalityIncidentMonthCount,fatalityIncidentYearCount,securityIncidentMonthCount,securityIncidentYearCount"
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlResponse = this._graphqlService.graphQLQuery(folderStruct);

    return this._http.post<any>(this._graphqlService.endpoint, graphqlResponse, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }
}
