import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { isString, isArray, isObject } from 'util';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  endpoint: string;
  response: IResposne;
  authorizationToken: string;
  resultString: string;
  constructor(private http: HttpClient) {
    // this.endpoint = "http://127.0.0.1:8080/graphql"
    // this.endpoint = 'https://api.ihssgenius.co.za:443/graphql'
    this.endpoint = 'https://api.ihssgenius.com:443/graphql';
  }

  graphQLMutationUser(mapStruct): string {
    this.resultString = '';
    let queryData = this.parseUserMap(mapStruct.data);
    if (queryData == '' || queryData == null) {
      return `{"query":"mutation{${mapStruct.function}{${mapStruct.returnData.keys}}}"}`.toString();
    } else {
      return `{"query":"mutation{${mapStruct.function}(${queryData}){${mapStruct.returnData.keys}}}"}`.toString();
    }
  }

  graphQLMutationAuditCompliance(mapStruct): string {
    this.resultString = '';
    let queryData = this.parseAuditMap(mapStruct.data)
    if (queryData == "" || queryData == null) {
      return `{"query":"mutation{${mapStruct.function}{${mapStruct.returnData.keys}}}"}`.toString()
    } else {
      return `{"query":"mutation{${mapStruct.function}(${queryData}){${mapStruct.returnData.keys}}}"}`.toString()
    }
  }

  graphQLMutation(mapStruct): string {
    this.resultString = '';
    let queryData = this.parseMap(mapStruct.data)
    if (queryData == "" || queryData == null) {
      return `{"query":"mutation{${mapStruct.function}{${mapStruct.returnData.keys}}}"}`.toString()
    } else {
      return `{"query":"mutation{${mapStruct.function}(${queryData}){${mapStruct.returnData.keys}}}"}`.toString()
    }
  }

  graphQLFileUpload(mapStruct): string {
    this.resultString = '';
    let queryData = this.parseMap(mapStruct.data)
    return `{"query":"query($file:Upload){${mapStruct.function}(${queryData}){${mapStruct.returnData.keys}}}","variables":{"file":null}}`.toString()
  }

  graphQLQuery(mapStruct): string {
    this.resultString = '';
    let queryData = this.parseMap(mapStruct.data)
    if (queryData == "" || queryData == null) {
      // return `{"query":"{${mapStruct.function}${mapStruct.returnData.keys}}"}`.toString()
      return `{"query":"{${mapStruct.function}{${mapStruct.returnData.keys}}}"}`.toString()
    } else {
      return `{"query":"{${mapStruct.function}(${queryData}){${mapStruct.returnData.keys}}}"}`.toString()
    }
  }

  parseMap(map) {
    let jsonMap = JSON.parse(JSON.stringify(map))
    for (let [key, value] of Object.entries(jsonMap)) {
      if (key === 'file') {
        this.resultString = this.resultString + `${key}:$file,`
      } else if (isArray(value)) {
        this.getFormattedArray(key, value);
        this.resultString = this.resultString.substr(this.resultString.length - 2) !== "]," ? this.resultString + "]," : this.resultString;
      } else if (isObject(value)) {
        this.resultString = this.resultString + key + ":" + "{";
        Object.keys(value).forEach((objKey) => {
          if (!isObject(value[objKey])) {
            this.resultString = this.resultString + (objKey + ":" + JSON.stringify(value[objKey]).replace(/"/g, "\\\"") + ",");
          } else if (isArray(value[objKey])) {
            this.getFormattedArray(objKey, value[objKey]);
          } else if (isObject(value[objKey])) {
            this.resultString = this.resultString + objKey + ":" + "{";
            this.parseMap(value[objKey]);
            this.resultString = this.resultString.substr(this.resultString.length - 1) === "," ? this.resultString.slice(0, -1) : this.resultString + "},";
          }
        });
        this.resultString = this.resultString.slice(0, -1) + "},"
      } else {
        this.resultString = this.resultString + (key + ":" + JSON.stringify(value).replace(/"/g, "\\\"") + ",")
      }
    }
    this.resultString = this.resultString.slice(0, -1);

    return this.resultString
  }

  getFormattedArray(key, value) {
    let arrayValue = value as Array<any>;
    this.resultString = this.resultString + key + ":" + "[";
    arrayValue.forEach(item => {
      this.resultString = this.resultString + "{"
      for (let [key2, value2] of Object.entries(item)) {
        if (isArray(value2)) {
          this.getFormattedArray(key2, value2);
          // this.resultString = this.resultString + "],".. commented

          //new added
          this.resultString = this.resultString.substr(this.resultString.length - 2) !== "]," ? this.resultString + "]," : this.resultString;
        } else {
          this.resultString = this.resultString + (key2 + ":" + JSON.stringify(value2).replace(/"/g, "\\\"") + ",")
        }
      }
      this.resultString = this.resultString.slice(0, -1) + "},"
    });

    //new added
    if (this.resultString.substr(this.resultString.length - 1) === ",") {
      this.resultString = this.resultString.substring(0, this.resultString.length - 1)
    }
    this.resultString = this.resultString.substr(this.resultString.length - 2) !== "]," ? this.resultString + "]," : this.resultString;
  }

  parseUserMap(map) {
    let jsonMap = JSON.parse(JSON.stringify(map))
    let string = ""
    let tmp2 = ""
    for (let [key, value] of Object.entries(jsonMap)) {
      if (key === 'file') {
        string = string + `${key}:$file,`
      } else if (isArray(value)) {
        let arrayValue = value as Array<any>
        arrayValue.forEach(item => {
          let tmp = ""
          for (let [key2, value2] of Object.entries(item)) {
            tmp = tmp + (key2 + ":" + JSON.stringify(value2).replace(/"/g, "\\\"") + ",")
          }
          tmp2 = tmp2 + "{" + tmp.slice(0, -1) + "},"
          tmp = ""
        })
        string = string + key + ":" + "[" + tmp2.slice(0, -1) + "],"
        tmp2 = ""
      } else {
        if (key === 'permissions') {
          string = string + (key + ":" + JSON.stringify(value).replace(/"/g, "") + ",")
        } else {
          string = string + (key + ":" + JSON.stringify(value).replace(/"/g, "\\\"") + ",")
        }

      }
    }
    return (string.slice(0, -1))
  }



  parseAuditMap(map) {
    let jsonMap = JSON.parse(JSON.stringify(map))
    for (let [key, value] of Object.entries(jsonMap)) {
      if (key === 'file') {
        this.resultString = this.resultString + `${key}:$file,`
      } else if (isArray(value)) {
        this.getFormattedArray(key, value);
        this.resultString = this.resultString.substr(this.resultString.length - 2) !== "]," ? this.resultString + "]," : this.resultString;
      } else if (isObject(value)) {
        this.resultString = this.resultString + key + ":" + "{";
        Object.keys(value).forEach((objKey) => {
          if (!isObject(value[objKey])) {
            this.resultString = this.resultString + (objKey + ":" + JSON.stringify(value[objKey]).replace(/"/g, "\\\"") + ",");
          } else if (isArray(value[objKey])) {
            this.getFormattedArray(objKey, value[objKey]);
          } else if (isObject(value[objKey])) {
            this.resultString = this.resultString + objKey + ":" + "{";
            this.parseMap(value[objKey]);
            this.resultString = this.resultString.substr(this.resultString.length - 1) === "," ? this.resultString.slice(0, -1) : this.resultString + "},";
          }
        });
        this.resultString = this.resultString.slice(0, -1) + "},"
      } else {
        if (key === 'status') {
          this.resultString = this.resultString + (key + ":" + JSON.stringify(value).replace(/"/g, "") + ",")
        } else {
          this.resultString = this.resultString + (key + ":" + JSON.stringify(value).replace(/"/g, "\\\"") + ",")
        }

      }
    }
    this.resultString = this.resultString.slice(0, -1);

    return this.resultString
  }

  formatResponse(result): IResposne {
    if (result['errors']) {
      this.response = {
        success: false,
        data: result['errors'][0].message
      }
    } else {
      this.response = {
        success: true,
        data: result['data']
      }
    }
    return this.response
  }
}

export interface IResposne {
  success: boolean;
  data: any
}
