import { Component, OnInit, Input } from '@angular/core';
import { NavParams, PopoverController, ModalController } from '@ionic/angular';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {

  faTrashAlt = faTrashAlt;
  title: string;
  body: string;
  action: string;

  constructor(
    public _navParams: NavParams,
    public _modalController: ModalController,
    public _popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.title = this._navParams.get('title');
    this.action = this._navParams.get('action');
    this.body = this._navParams.get('body')
  }

  closeModal() {
    this._modalController.dismiss({ 'action': false });
  }

  async continue() {
    await this._modalController.dismiss({ 'action': true });
  }

}
