import { Component, OnInit } from '@angular/core';
import { faUsers, faServer, faBuilding, faPaste } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {

  faUsers = faUsers;
  faServer = faServer;
  faBuilding = faBuilding
  faPaste = faPaste;

  constructor(private _router: Router,
    private _popoverCtrl: PopoverController) { }

  ngOnInit() { }

  async goToUserManagement() {
    await this._popoverCtrl.dismiss();
    this._router.navigate(['/ihss/usermanagement']);
  }

  async goToServerGroup() {
    await this._popoverCtrl.dismiss();
    this._router.navigate(['/ihss/servergroup']);
  }

  async goToCompanyManagement() {
    await this._popoverCtrl.dismiss();
    this._router.navigate(['/ihss/companymanagement']);
  }

  async goToTemplates() {
    await this._popoverCtrl.dismiss();
    this._router.navigate(['/ihss/templatemanagement']);
  }
}
