import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IResposne } from 'src/app/services/graphql.service';
import { FolderService } from '../services/folder.service';
import { FoldersComponent } from '../folders/folders.component';
import { Storage } from '@ionic/storage';
import { ToastService } from '../../toast';

@Component({
  selector: 'app-new-folder-modal',
  templateUrl: './new-folder-modal.component.html',
  styleUrls: ['./new-folder-modal.component.scss'],
})
export class NewFolderModalComponent implements OnInit {

  public value;
  public name: string;
  public access_token
  onSubmit:boolean = false;

  constructor(private _modalController: ModalController,
    private _folderService: FolderService,
    private _storage: Storage,
    private _toastService: ToastService) { }

  ngOnInit() {
    this.name = this._folderService.name
    this._storage.get('access_token').then((value) => {
      this.access_token = value
    });
  }

  createFolder() {
    if (!this.value) {    
      this.onSubmit = true;
      this._toastService.show({
        text: `Please input a folder name.`,
        type: 'danger',
      });
      return;
    }
    this._folderService.createFolderGraphQL(this.value, this.access_token).subscribe((res: IResposne) => {
      if (res.success) {
        this._modalController.dismiss({ 'added': true });
        this._toastService.show({
          text: `Folder '${this.value}' is created successfully.`,
          type: 'success',
        });
      } else {
        this._toastService.show({
          text: `${res.data}`,
          type: 'danger',
        });
      }
    })
  }
}
