import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  @Input() myDate = new Date(); //(assign your selected date to this Object)

  disabledDates: Date[] = [
    new Date(1545911005644),
    new Date(),
    new Date(2018, 12, 12), // Months are 0-based, this is August, 10th.     
    new Date('Wednesday, December 26, 2018'), // Works with any valid Date formats like long format     
    new Date('12-14-2018'), // Short format
  ];

  datePickerObj: any = {
    inputDate: new Date('2018-08-10'), // default new Date()
    fromDate: new Date('2016-12-08'), // default null
    toDate: new Date('2018-12-28'), // default null
    showTodayButton: false, // default true
    closeOnSelect: true, // default false
    disableWeekDays: [4], // default []
    mondayFirst: true, // default false
    setLabel: 'S',  // default 'Set'
    todayLabel: 'T', // default 'Today'
    closeLabel: 'C', // default 'Close'
    // disabledDates: disabledDates, // default []
    titleLabel: 'Select a Date', // default null
    monthsList: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    weeksList: ["S", "M", "T", "W", "T", "F", "S"],
    dateFormat: 'YYYY-MM-DD', // default DD MMM YYYY
    clearButton: false, // default true
    momentLocale: 'pt-BR', // Default 'en-US'
    yearInAscending: true, // Default false
    btnCloseSetInReverse: true, // Default false
    btnProperties: {
      expand: 'block', // Default 'block'
      fill: '', // Default 'solid'
      size: '', // Default 'default'
      disabled: '', // Default false
      strong: '', // Default false
      color: '' // Default ''
    },
    arrowNextPrev: {
      nextArrowSrc: 'assets/images/arrow_right.svg',
      prevArrowSrc: 'assets/images/arrow_left.svg'
    }, // This object supports only SVG files.
    highlightedDates: [
      { date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff' },
      { date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff' }
    ], // Default [],
    isSundayHighlighted: {
      fontColor: '#ee88bf' // Default null
    } // Default {}
  };

  constructor() { }

  ngOnInit() { }

}
