import { Injectable } from '@angular/core';
import { GraphqlService, IResposne } from 'src/app/services/graphql.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TextEditorService {

  constructor(private _graphqlService: GraphqlService,private _http: HttpClient) { }

  
  saveTemplate(access_token,templateFunctionName,templateDocumentName,value){
    let struct = {
      function: templateFunctionName,
      data: {
        templateName: templateDocumentName,
        templateText: encodeURI(value)
      },
      returnData: {
        keys: "id,templateName,templateText"
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlResponse = this._graphqlService.graphQLMutation(struct)

    return this._http.post<any>(this._graphqlService.endpoint, graphqlResponse, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));

  }

  deleteTemplate(access_token,templateFunctionName,id){
    let struct = {
      function: templateFunctionName,
      data: {
        id: id,
      },
      returnData: {
        keys: "id,templateName,templateText"
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlResponse = this._graphqlService.graphQLMutation(struct)

    return this._http.post<any>(this._graphqlService.endpoint, graphqlResponse, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));

  }

  viewTemplates(access_token,templateFunctionName){
    let struct = {
      function: templateFunctionName,
      data: {

      },
      returnData: {
        keys: "id,templateName,templateText"
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlResponse = this._graphqlService.graphQLQuery(struct)
    return this._http.post<any>(this._graphqlService.endpoint, graphqlResponse, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }

}
