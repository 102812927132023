import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-name-signpad',
  templateUrl: './name-signpad.component.html',
  styleUrls: ['./name-signpad.component.scss'],
})
export class NameSignpadComponent implements OnInit {

  @Input() mode: string;
  @Input() submit: boolean;

  public formApprovedBy: FormGroup;
  @ViewChild(SignaturePad, { static: false }) signaturePad: any;

  public formData: any;
  public signed: boolean = false;
  emptyFocus: boolean = false;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'dotSize': 0.1,
    'minWidth': 0,
    'canvasWidth': 406,
    'canvasHeight': 100,
    'canvasBorder': '1px solid #000'
  };

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formApprovedBy = this._formBuilder.group({
      approvedBy: ['', Validators.required],
      designation: ['', Validators.required],
      signedDate: ['', Validators.required],
      signature: ['', Validators.required]
    });
  }

  drawStart() {
    if (this.mode === "view") {
      this.signaturePad.off();
    }

    if (this.signaturePad.signaturePad.points.length > 1) {
      this.emptyFocus = false;
      this.formApprovedBy.controls['signature'].patchValue(this.signaturePad.toDataURL())
      this.signed = true;
    } else {
      this.emptyFocus = true;
    }

  }

  drawComplete() {
    // this.signaturePad.off() 

    if (this.mode === "view") {
      this.signaturePad.off();
    }
    this.emptyFocus = false;
    if (this.signaturePad.signaturePad.points.length > 1) {
      this.formApprovedBy.controls['signature'].patchValue(this.signaturePad.toDataURL())
      this.signed = true;
    } else {
      this.emptyFocus = true;
    }
  }


  clearSign() {
    this.signaturePad.clear();
  }


  setApprovedBy() {
    this.formApprovedBy.controls['approvedBy'].patchValue('');
    this.formApprovedBy.controls['designation'].patchValue('');
    this.formApprovedBy.controls['signedDate'].patchValue('');
    this.formApprovedBy.controls['signature'].patchValue('');
  }

  ngAfterViewInit() {
    this.signaturePad.fromDataURL(this.formData);
  }

  // public validateApprovedBy(): boolean {
  //   if (!this.formApprovedBy.valid) {
  //     return false;
  //   }
  //   return true;
  // }

  // public getApprovedBy(): any {
  //   let approvedBy = {
  //     name: this.formApprovedBy.controls["approvedBy"].value,
  //     designation: this.formApprovedBy.controls["designation"].value,
  //     date: this.formApprovedBy.controls["signedDate"].value,
  //     signature: this.formApprovedBy.controls["signature"].value,
  //   }
  //   return approvedBy;
  // }
}
