import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UploadService } from './services/upload-item-modal.service';
import { IResposne } from 'src/app/services/graphql.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadFormDocComponent implements OnInit {

  public modalHeader: string = '';
  public formTextValue: string = '';
  selectedFile: File
  operations: any;
  map: any;
  access_token: string;
  folderId: number;
  funcName: string;
  uploadedUrl: string;
  isFileSelected: boolean = false;
  isWCLForm: boolean;
  isPreUpload: boolean;
  wclform: string = '';
  isCreateIncidentForm: boolean;
  onSubmit: boolean = false;
  itemType: string;
  itemId: number;

  constructor(
    private _storage: Storage,
    private _modalController: ModalController,
    public _navParams: NavParams,
    private _toastService: ToastService,
    private _uploadService: UploadService) { }


  ngOnInit() {
    this.modalHeader = this._navParams.get('modalHeader') || 'No Module Name';
    this.folderId = this._navParams.get('folderId') || undefined;
    this.funcName = this._navParams.get('funcName');
    this.uploadedUrl = this._navParams.get('uploadedUrl');
    this.isWCLForm = this._navParams.get('isWCL') || false;
    this.isPreUpload = this._navParams.get('preUpload') || undefined;
    this.isCreateIncidentForm = this._navParams.get('isCreateIncidentForm') || false;
    this.itemType = this._navParams.get('itemType') || undefined;
    this.itemId = this._navParams.get('itemId') || undefined;

    this._storage.get('access_token').then((token: string) => {
      this.access_token = token;
    });
  }

  onFileChanged(event) {
    this.isFileSelected = true;
    this.selectedFile = event.target.files[0]
  }

  uploadDocument() {
    var file = this.selectedFile;
    this.onSubmit = true;

    if (!this.isWCLForm && (!this.formTextValue && !this.isFileSelected)) {
      this.showMessage('Please input a name and upload the document.', 'danger');
      return;
    }

    if (!this.isWCLForm && (!this.formTextValue && this.isFileSelected)) {
      this.showMessage('Please input a name.', 'danger');
      return;
    }

    if (!this.isWCLForm && (this.formTextValue && !this.isFileSelected)) {
      this.showMessage('Please upload a document.', 'danger');
      return;
    }

    if (this.isWCLForm && (this.wclform === "" && !this.isFileSelected)) {
      this.showMessage('Please select a WCL Type and upload the document.', 'danger');
      return;
    }

    if (this.isWCLForm && (this.wclform !== "" && !this.isFileSelected)) {
      this.showMessage('Please upload a document.', 'danger');
      return;
    }

    if (this.isWCLForm && (this.wclform !== "" && this.isFileSelected && this.selectedFile.type !== 'application/pdf')) {
      this.showMessage('Please upload PDF document only.', 'danger');
      return;
    }


    if (!this.isCreateIncidentForm) {
      if (this.folderId) {
        this._uploadService.uploadDocument(this.access_token, this.funcName,
          this.formTextValue, this.selectedFile, this.folderId, this.uploadedUrl).subscribe((res: IResposne) => {
            if (res.success) {
              this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
              this.showMessage('Document uploaded successfully.', 'success');
            } else {
              this.showMessage(res.data, 'danger');
            }
          });
      } else {
        if (this.isPreUpload) {
          this._uploadService.uploadPreDocument(this.access_token, this.funcName,
            this.formTextValue, this.selectedFile, this.uploadedUrl).subscribe((res: IResposne) => {
              if (res.success) {
                this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
                this.showMessage('Document uploaded successfully.', 'success');
              } else {
                this.showMessage(res.data, 'danger');
              }
            });
        } else {
          this._uploadService.uploadPostDocument(this.access_token, this.funcName,
            this.formTextValue, this.selectedFile, this.uploadedUrl, this.itemId, this.itemType).subscribe((res: IResposne) => {
              if (res.success) {
                this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
                this.showMessage('Document uploaded successfully.', 'success');
              } else {
                this.showMessage(res.data, 'danger');
              }
            });
        }
      }

    } else {
      if (this.isPreUpload) {
        if (!this.wclform) {
          this._uploadService.uploadPreDocument(this.access_token, this.funcName,
            this.formTextValue, this.selectedFile, this.uploadedUrl).subscribe((res: IResposne) => {
              if (res.success) {
                this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
                this.showMessage('Photos uploaded successfully.', 'success');
              } else {
                this.showMessage(res.data, 'danger');
              }
            });
        } else {
          this._uploadService.uploadPreWCLForm(this.access_token, this.funcName,
            this.wclform, this.selectedFile, this.uploadedUrl).subscribe((res: IResposne) => {
              if (res.success) {
                this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
                this.showMessage('Photos uploaded successfully.', 'success');
              } else {
                this.showMessage(res.data, 'danger');
              }
            });
        }
      } else {
        if (!this.wclform) {
          this._uploadService.uploadPostDocument(this.access_token, this.funcName,
            this.formTextValue, this.selectedFile, this.uploadedUrl, this.itemId, this.itemType).subscribe((res: IResposne) => {
              if (res.success) {
                this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
                this.showMessage('Photos uploaded successfully.', 'success');
              } else {
                this.showMessage(res.data, 'danger');
              }
            });
        } else {
          this._uploadService.uploadPostWCLForm(this.access_token, this.funcName,
            this.wclform, this.selectedFile, this.uploadedUrl, this.itemId, this.itemType).subscribe((res: IResposne) => {
              if (res.success) {
                this._modalController.dismiss({ 'docName': this.formTextValue, 'data': res.data });
                this.showMessage('Photos uploaded successfully.', 'success');
              } else {
                this.showMessage(res.data, 'danger');
              }
            });
        }
      }
    }
  }

  closeModal() {
    this._modalController.dismiss({ 'docName': this.formTextValue });
  }

  selectedWCLForm(value) {
    this.wclform = value;
  }

  showMessage(message, option) {
    this._toastService.show({
      text: `${message}`,
      type: option,
    });
  }
}
