import { Component, OnInit } from '@angular/core';
import { IResposne } from 'src/app/services/graphql.service';
import { Storage } from '@ionic/storage';
import { GetFaultyCountService } from 'src/app/services/getfaultycount.service';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-faulty-chart',
  templateUrl: './faulty-chart.component.html',
  styleUrls: ['./faulty-chart.component.scss'],
})
export class FaultyChartComponent implements OnInit {

  public access_token: string;
  barfaultyChartData: ChartDataSets[] = [{ data: [], label: 'All Incident By Assignment' }];
  barChartLabels: Label[] = ['Current Month', 'Year'];
  barChartType = 'bar'
  public barChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            max: 100,
            beginAtZero: true
          }
        }
      ],
    },
    pan: {
      enabled: true,
      mode: 'xy'
    },
    zoom: {
      enabled: true,
      mode: 'xy'
    },
  };

  public barChartColors: Color[] = [
    { backgroundColor: ['#E37616', '#A42B9F'] },
  ];

  public barChartLegend = true;
  faultyCounts: any = {};

  constructor(private _getFaultyCountService: GetFaultyCountService,
    private _storage: Storage) { }

  ngOnInit() {
    this._storage.get('access_token').then((value) => {
      this.access_token = value
      this.getFaultCounts()
    });
  }

  getFaultCounts() {
    this._getFaultyCountService.getfaultCount(this.access_token)
      .subscribe((res: IResposne) => {
        this.faultyCounts = { ...res.data.faultCounts };

        this.barfaultyChartData = [
          {
            data: [this.faultyCounts.faultsMonthCount,this.faultyCounts.faultsYearCount], label: 'Faults'
          }
        ];
      });
  }

}
