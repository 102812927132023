import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit {


  constructor(private _modalController: ModalController) { }

  ngOnInit() { }

  closeModal() {
    this._modalController.dismiss();
  }
}
