import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ModalController } from '@ionic/angular';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { SignatureService } from '../signature-pad/services/signature.service';
import { TextEditorService } from './services/text-editor.service';
import { IResposne } from 'src/app/services/graphql.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit {

  public value;
  public templateDocumentName;
  public templateFunctionName;
  public deleteTemplateID
  public access_token;
  @Output('textEditor') textEditor: EventEmitter<string> = new EventEmitter<any>();
  @ViewChild('sample', { static: true }) public rteObj: RichTextEditorComponent;
  @Input() showSign: boolean;
  @Input() enabled: boolean = true;
  isSignUploaded: boolean = false;
  @Input() showDownloadPdf: boolean = true;

  @Output('isDocSigned') isDocSigned: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|', 'Undo', 'Redo', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      'Image']
  }

  imageSettings = {
    saveFormat: "Base64"
  }

  constructor(
    private _modalController: ModalController,
    private _signatureService: SignatureService,
    private _textEditorService: TextEditorService,
    private _storage: Storage
  ) {
  }


  ngOnInit() {
    const instance: any = this.rteObj;
    this.value = this.rteObj.getHtml();
    this._signatureService.signature$.subscribe((signDoc) => {
      if (signDoc) {
        // if (this.isSignUploaded)
        // this.value = this.value.replace(/<img[^>]*>/g, "")
        this.value = this.value !== null ? this.value : '';
        this.value += `<img src="${signDoc}"/>`;
        instance.updateValue();
        // this.isSignUploaded = true;
        this._signatureService.clearSignature();
        this.isDocSigned.emit(true);
        // 
      }
      // else {
      //   if (this.isSignUploaded) {
      //     this.value = this.value.replace(/<img[^>]*>/g, "")
      //     instance.updateValue();
      //     this.isSignUploaded = false;
      //   }
      // }
    })
    this._storage.get('access_token').then((value) => {
      this.access_token = value
    });
  }

  getTextEditorValue(): any {
    return this.value;
  }

  setTextEditorValue(value, isDocSigned = false) {
    this.value = value;
    // if (isDocSigned) {
    //   let regex = /<img.*?src="(.*?)"/g;
    //   let imgSrc = regex.exec(value)[1];
    //   this._signatureService.setSignature(imgSrc)
    // }
  }

  resetTextEditor() {
    this.value = '';
  }

  viewPDF() {
    const instance: any = this.rteObj;
    instance.updateValue();
    let htmlElement = this.rteObj.getHtml()
    this.createElementFromHTML(htmlElement)
  }

  createElementFromHTML(htmlElement) {
    let mywindow = window.open('', 'pdf', 'height=400,width=600')
    mywindow.document.write(htmlElement)
    mywindow.print();
    mywindow.close();
  }

  // deleteSelectedFolder(access_token, name, folder) {
  //   let functionName = this.folders.find(i => i.name === name)
  //   // this.folders.find(i => i.name === this.name).folders = []
  //   let graphqlStruct = {
  //     function: functionName.deleteFunction,
  //     data: {
  //       id: folder.id
  //     },
  //     returnData: {
  //       keys: "id"
  //     }
  //   }

  //   const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

  //   let graphqlResponse = this._graphqlService.graphQLMutation(graphqlStruct)

  //   return this._http.post<any>(this._graphqlService.endpoint, graphqlResponse, { headers: customHeaders })
  //     .pipe(map((response: IResposne) => {
  //       return this._graphqlService.formatResponse(response);
  //     }));
  // }

  saveTemplate() {
    const instance: any = this.rteObj;
    instance.updateValue();
    let htmlElement = this.rteObj.getHtml()
    this._textEditorService.saveTemplate(this.access_token, this.templateFunctionName, this.templateDocumentName, htmlElement).subscribe((res: IResposne) => {
      if (res.success) {
      }
    })
  }

  deleteTemplate() {
    this._textEditorService.deleteTemplate(this.access_token, this.templateFunctionName, parseInt(this.deleteTemplateID)).subscribe((res: IResposne) => {
      if (res.success) {
      }
    })
  }

  viewTemplates() {
    this._textEditorService.viewTemplates(this.access_token, this.templateFunctionName).subscribe((res: IResposne) => {
      if (res.success) {
      }
    })
  }
}
