import { Component, OnInit, ViewChild, Renderer2, Renderer } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {

  accordionExpanded: boolean = false;

  constructor(private _renderer: Renderer) { }

  ngOnInit() {
    setTimeout(() => {
      // this._renderer.setElementStyle(this.cardContent.el, "webkitTransition", "display");
    }, 200);
  }


  toogleAccordion() {
    this.accordionExpanded = !this.accordionExpanded;
    // if (this.accordionExpanded) {
    //   this._renderer.setElementStyle(this.cardContent.el, "display", "block");
    // } else {
    //   this._renderer.setElementStyle(this.cardContent.el, "display", "none");
    // }
  }

}
