import { Component, OnInit } from '@angular/core';
import { faPowerOff, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ILoginUser } from 'src/app/pages/login/model/user-interface';
import { AuthService } from 'src/app/pages/login/services/auth.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  faPowerOff = faPowerOff;
  faUserCircle = faUserCircle;
  userProfile: ILoginUser;

  constructor(
    private _router: Router,
    public _popoverCtrl: PopoverController,
    private authService: AuthService,
    private storage: Storage) { }

  ngOnInit() {
    this.storage.get('user_profile').then(userprofile => {
      this.userProfile = userprofile;
    });
  }

  async logOut() {
    await this._popoverCtrl.dismiss();
    this.authService.logout();
    this._router.navigate(['/login']);
  }

}
