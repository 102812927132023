import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FolderService } from '../services/folder.service';
import { IResposne } from 'src/app/services/graphql.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NewFolderModalComponent } from './../new-folder-modal/new-folder-modal.component'
import { Storage } from '@ionic/storage';
import { faTrashAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { ToastService } from '../../toast';

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss'],
})
export class FoldersComponent implements OnInit {

  @Input() titleName: string;
  @Output('selectedFolderObj') selectedFolderObj: EventEmitter<{}> = new EventEmitter<{}>();
  @Output('removeFolder') removeFolder: EventEmitter<{}> = new EventEmitter<{}>();

  public name: string;
  public folders: any[] = [];
  public access_token: string;
  public spinner = true;
  faTrashAlt = faTrashAlt;
  faFolderOpen = faFolderOpen;
  profilePic: boolean = false;
  @Input() displayTitle: string = '';
  folderSectionTitle: string = '';

  constructor(
    private _folderService: FolderService,
    private router: Router,
    private _modalController: ModalController,
    private _toastService: ToastService,
    private _storage: Storage) {

  }

  ngOnInit() {
    this._storage.get('access_token').then((value) => {
      this.access_token = value
      this.queryFolders()
    });
    this.name = this._folderService.setupName(this.titleName);
    this.folderSectionTitle = this.name;
    if (this.displayTitle) {
      this.folderSectionTitle = this.displayTitle;
    }
  }

  queryFolders() {
    let name = this.name;
    if (name.split(' ').length > 1) {
      name = name.split(' ').join('').toLowerCase();
    }
    let struct = this._folderService.folders.find(i => i.name === name)
    this._folderService.queryFolders(this.access_token, name).subscribe((res: IResposne) => {
      this.spinner = false
      if (res.success) {
        (res.data[struct.functionQuery] || []).forEach((folder: any) => {
          this._folderService.addToStruct(folder.id, folder.name)
          this.folders = struct.folders;
        });
      } else {
        this.showMessage(res.data, 'danger');
      }
    })
  }

  selectedFolder(folder) {
    let name = this.name;
    if (name.split(' ').length > 1) {
      name = name.split(' ').join('').toLowerCase();
    }
    let struct = this._folderService.folders.find(i => i.name === name)
    this.selectedFolderObj.emit({ 'id': folder.id, 'route': struct.route })
  }

  async openModal() {
    const modal = await this._modalController.create({
      component: NewFolderModalComponent,
      cssClass: 'formModal',
      backdropDismiss: true,
    });
    modal.onDidDismiss().then((result) => {
      if (result.data && result.data.added) {
        this.queryFolders()
      }
    });
    await modal.present();
  }

  async removeSelectedFolder(folder) {
    const modal = await this._modalController.create({
      component: ConfirmModalComponent,
      cssClass: 'deleteModal',
      backdropDismiss: true,
      componentProps: {
        'title': `DELETE FOLDER - ${this.name}`,
        'body': folder.name,
        'action': 'delete'
      },
    });

    modal.onDidDismiss().then((result) => {
      if (result.data.action) {
        let name = this.name;
        if (name.split(' ').length > 1) {
          name = name.split(' ').join('').toLowerCase();
        }
        let deleteFunction = this._folderService.folders.find(i => i.name === name).deleteFunction

        this._folderService.deleteSelectedFolder(this.access_token, name, folder)
          .subscribe((res: IResposne) => {
            if (res.success) {
              this.removeByAttr(this.folders, 'id', res.data[deleteFunction].id);
              this.showMessage(`Folder '${folder.name}' is deleted successfully`, 'success');
            } else {
              this.showMessage(res.data, 'danger');
            }
          });
      }
    });
    await modal.present();
  }


  removeByAttr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  showMessage(message, option) {
    this._toastService.show({
      text: `${message}`,
      type: option,
    });
  }
}
