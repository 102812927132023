import { Injectable } from '@angular/core';
import { GraphqlService, IResposne } from 'src/app/services/graphql.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private _graphqlService: GraphqlService,
    private _http: HttpClient) { }

  uploadDocument(access_token, functionName, fileName, file, folderId, fileUrl) {
    let updateStruct = {
      function: functionName,
      data: {
        fileName: fileName,
        folderId: folderId,
        file: file
      },
      returnData: {
        keys: `id,fileName,${fileUrl},folderId,dateUploaded`,
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlOperations = this._graphqlService.graphQLFileUpload(updateStruct);

    const formData = new FormData()
    formData.append('file', file)
    formData.append('operations', graphqlOperations)
    formData.append('map', JSON.stringify({
      file: ["variables.file"]
    }))

    return this._http.post<any>(this._graphqlService.endpoint, formData, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }

  uploadPreDocument(access_token, functionName, fileName, file, fileUrl) {
    let updateStruct = {
      function: functionName,
      data: {
        fileName: fileName,
        file: file
      },
      returnData: {
        keys: `id,fileName,${fileUrl},dateUploaded`,
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlOperations = this._graphqlService.graphQLFileUpload(updateStruct);

    const formData = new FormData()
    formData.append('file', file)
    formData.append('operations', graphqlOperations)
    formData.append('map', JSON.stringify({
      file: ["variables.file"]
    }))

    return this._http.post<any>(this._graphqlService.endpoint, formData, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }

  uploadPostDocument(access_token, functionName, fileName, file, fileUrl, id, uploadOperationType) {
    let updateStruct = {
      function: functionName,
      data: {
        fileName: fileName,
        file: file,
        [uploadOperationType]: id
      },
      returnData: {
        keys: `fileName,${fileUrl},dateUploaded`,
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlOperations = this._graphqlService.graphQLFileUpload(updateStruct);

    const formData = new FormData()
    formData.append('file', file)
    formData.append('operations', graphqlOperations)
    formData.append('map', JSON.stringify({
      file: ["variables.file"]
    }))

    return this._http.post<any>(this._graphqlService.endpoint, formData, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }

  uploadPreWCLForm(access_token, functionName, wclFormType, file, fileUrl) {
    let updateStruct = {
      function: functionName,
      data: {
        wclFormType: wclFormType,
        file: file
      },
      returnData: {
        keys: `id,wclFormType,${fileUrl},dateUploaded`,
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlOperations = this._graphqlService.graphQLFileUpload(updateStruct);

    const formData = new FormData()
    formData.append('file', file)
    formData.append('operations', graphqlOperations)
    formData.append('map', JSON.stringify({
      file: ["variables.file"]
    }))

    return this._http.post<any>(this._graphqlService.endpoint, formData, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }

  uploadPostWCLForm(access_token, functionName, wclFormType, file, fileUrl, incidentId, incidentType) {
    let updateStruct = {
      function: functionName,
      data: {
        wclFormType: wclFormType,
        file: file,
        [incidentType]: incidentId
      },
      returnData: {
        keys: `wclFormType,${fileUrl},dateUploaded`,
      }
    }

    const customHeaders: HttpHeaders = new HttpHeaders().append('Authorization', access_token);

    let graphqlOperations = this._graphqlService.graphQLFileUpload(updateStruct);

    const formData = new FormData()
    formData.append('file', file)
    formData.append('operations', graphqlOperations)
    formData.append('map', JSON.stringify({
      file: ["variables.file"]
    }))

    return this._http.post<any>(this._graphqlService.endpoint, formData, { headers: customHeaders })
      .pipe(map((response: IResposne) => {
        return this._graphqlService.formatResponse(response);
      }));
  }
}
