import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-signature-box',
  templateUrl: './signature-box.component.html',
  styleUrls: ['./signature-box.component.scss'],
})
export class SignatureBoxComponent implements OnInit {

  @Input() controlName: string;
  @Input() signaturedata: any;
  @Input() submit: boolean = false;
  @Input() mode: string;
  @ViewChild(SignaturePad, { static: false }) signaturePad: any;
  @Output() signature = new EventEmitter<any>();
  public signed: boolean = false;
  emptyFocus: boolean = false;
  @Input() height: number
  @Input() width: number;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'dotSize': 0.1,
    'minWidth': 0,
    'canvasWidth': this.width || 415,
    'canvasHeight': this.height || 85,
    'canvasBorder': '1px solid #000'
  };

  constructor() { }

  ngOnInit() {
    this.signaturePadOptions["canvasHeight"] = this.height || 85;
    this.signaturePadOptions["canvasWidth"] = this.width || 415;
   }

  drawStart() {
    if (this.mode === "view") {
      this.signaturePad.off();
    }

    if (this.signaturePad.signaturePad.points.length > 1) {
      this.emptyFocus = false;
      // this.formApprovedBy.controls['signature'].patchValue(this.signaturePad.toDataURL())
      this.signed = true;
    } else {
      this.emptyFocus = true;
    }
  }

  drawComplete() {
    // this.signaturePad.off() 
    this.emptyFocus = false;
    if (this.mode === "view") {
      this.signaturePad.off();
    }

    if (this.signaturePad.signaturePad.points.length > 1) {
      // this.formApprovedBy.controls['signature'].patchValue(this.signaturePad.toDataURL())
      this.signature.emit({
        'controlName': this.controlName,
        'value': this.signaturePad.toDataURL()
      })
      this.signed = true;
    } else {
      this.emptyFocus = true;
      this.signature.emit({
        'controlName': this.controlName,
        'value': ''
      })
    }
  }

  ngAfterViewInit() {
    this.signaturePad.fromDataURL(this.signaturedata);
  }
}
