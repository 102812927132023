import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IUploadedItem } from './interface/uploadedItem-interface';
import { faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss'],
})
export class UploadDocumentsComponent implements OnInit {

  @Input() recordSet: IUploadedItem[] = [];
  @Input() noRecordsFoundText: string = '';
  @Output() removeItem = new EventEmitter<IUploadedItem>();
  faTrashAlt = faTrashAlt;
  faDownload = faDownload;
  @Input() modalTitle: string;
  @Input() isWCLDocs: boolean = false;
  @Input() hideDeleteButton: boolean = false;

  constructor(private _modalController: ModalController) { }

  ngOnInit() { }

  async removeSelectedItem(itemToDelete: IUploadedItem) {
    const modal = await this._modalController.create({
      component: ConfirmModalComponent,
      cssClass: 'deleteModal',
      backdropDismiss: true,
      componentProps: {
        'title': this.modalTitle,
        'body': itemToDelete.documentName,
        'action': 'delete'
      },
    });

    modal.onDidDismiss().then((result) => {
      if (result.data.action) {
        this.removeItem.emit(itemToDelete);
      }
    });
    await modal.present();
  }



  downloadWCLForm(file) {
    let link = document.createElement("a");
    link.download = `${file.wclFormType}`;
    link.href = `${file.documentUrl}`
    link.click();
  }
}
