import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface IToogleOption {
  text: string;
  selected: boolean
}

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {

  @Input() data: any;
  @Input() disabled: boolean;
  @Input() value: any;
  @Input() submitted: boolean = false;
  @Output() emitSelectedValue = new EventEmitter<string>();
  isValueSelected: boolean = false;
  constructor() { }

  ngOnInit() {

    if (this.value !== '' && this.value !== undefined) {
      this.setSelectedOption(this.value);
    }
  }

  ngAfterContentInit() {
  }

  setSelectedOption(selectedItem: string) {
    this.isValueSelected = true;
    this.data.map((item: IToogleOption) => {
      item.selected = false;
      if (item.text === selectedItem) {
        item.selected = true;
      }
    });
    this.emitSelectedValue.emit(selectedItem);
  }
}
