import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GraphqlService, IResposne } from 'src/app/services/graphql.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private _apiBase = environment.apibase;
  authenticationState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  user = null;
  redirectUrl: string;
  invalidUser: boolean;

  constructor(private _http: HttpClient,
    private _helper: JwtHelperService,
    private _storage: Storage,
    private _router: Router,
    private graphqlService: GraphqlService,
    private _plt: Platform) {
    this._plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this._storage.get('access_token').then(token => {
      if (token) {
        let decoded = this._helper.decodeToken(token);
        let isExpired = this._helper.isTokenExpired(token);

        if (!isExpired) {
          if (this.redirectUrl) {
            this._router.navigate([this.redirectUrl]);
          }
          this.user = decoded;
          this.authenticationState$.next(true);
        } else {
          this.authenticationState$.next(false);
          this._storage.remove('access_token');
        }
      }
    });
  }

  isLoggedIn(): boolean {
    return this.authenticationState$.value;
  }

  public login(userName: string, pwd: string): Observable<any> {

    // user{id,username,firstName,surname,email,userType,permissions{policies,riskAssessments}},token
    let loginStruct = {
      function: "login",
      data: {
        username: userName,
        password: pwd
      },
      returnData: {
        keys: "user{id, firstName, username, surname, email, userType},token"
      }
    }

    let graphqlResponse = this.graphqlService.graphQLMutation(loginStruct);
    return this._http.post<any>(this.graphqlService.endpoint, graphqlResponse)
      .pipe(map((response: IResposne) => {
        return this.graphqlService.formatResponse(response);
      }));
  }

  public getAuthStateObserver(): Observable<boolean> {
    return this.authenticationState$.asObservable();
  }


  public logout(): void {
    this.invalidUser = false
    this._storage.remove('access_token');
    this._storage.remove('user_profile');
    this._router.navigate(['/login']);
  }
}
