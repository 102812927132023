import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-risk-matrix',
  templateUrl: './risk-matrix.component.html',
  styleUrls: ['./risk-matrix.component.scss'],
})
export class RiskMatrixComponent implements OnInit {

  @Input() riskMatrix: boolean;
  @Input() actionMatrix: boolean;
  constructor() { }

  ngOnInit() { }

}
