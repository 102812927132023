import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../pages/login/services/auth.service';

@Injectable({ providedIn: "root" })

export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService,
        private _router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return true;
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.authService.redirectUrl = state.url;
        this._router.navigate(['/login']);
        return false;
    }


    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return true;
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this._router.navigate(['/login']);
        return false;
    }

}