import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SignatureService {

    public signature$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // public signed: boolean = false

    constructor() {

    }

    setSignature(signObj) {
        this.signature$.next(signObj);
    }

    clearSignature() {
        this.signature$.next(null);
    }

    unsubscribeSignature() {
        if (this.signature$)
            this.signature$.unsubscribe();
    }

    
}